import { Container, Row, Col, Form, Button } from "react-bootstrap"
import emailjs from "emailjs-com"
import '../CSS/Contact.css'

const Contactus = () => {

    function sendEmail(e){
        e.preventDefault();

        emailjs.sendForm('mail.diamondSMTP', 'template_ujqsfqf', e.target, 'user_loFEKz3RTrNJeJAgTaNtg')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
        e.target.reset()
    }
    
    return (
        <>
            <Container fluid className="contactwrapper">
                <Row>
                    <Col lg={6} md={6} sm={12} className="contactleft fontReg"
                    style={{padding:"0px 30px 0px 30px"}}>
                        <Form onSubmit={sendEmail}>
                            <h2 className="fontSB text-center">Get In Touch</h2>
                            <Form.Group className="mb-4" controlId="nameinput">
                                <Form.Label>Name</Form.Label>
                                <Form.Control 
                                type="text-only" 
                                placeholder="Your Name" 
                                name="name" 
                                required
                                />
                            </Form.Group>

                            <Form.Group className="mb-4" controlId="emailinput">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control 
                                type="email" 
                                placeholder="Enter email" 
                                name="email" 
                                required
                                />
                            </Form.Group>

                            <Form.Group className="mb-4" controlId="questioninput">
                                <Form.Label>Your Question</Form.Label>
                                <Form.Control
                                as="textarea"
                                placeholder="Leave your question here"
                                name="question"
                                style={{ height: '200px' }}
                                required
                                />
                            </Form.Group>

                            <Button variant="Custom" type="submit">
                                Submit
                            </Button>
                        </Form>
                    </Col>

                    <Col lg={6} md={6} sm={12} className="contactright fontReg">
                        <h2 className="fontSB">Contact Us</h2>
                        <hr style={{border:'2px solid', color:'#967A50', width:'30%', margin:'2px', opacity:'100'}}></hr>

                        <Row className="contactdetail">
                            <Col lg={6}>
                                <p>
                                <b>Address:</b> Jl. Lambung Mangkurat No. 56
                                Samarinda - East Kalimantan<br></br>
                                <b>Email:</b> info@diamondhotel.co.id <br></br>
                                </p>
                            </Col>

                            <Col lg={6}>
                            <p>
                                <b>Phone:</b> (+62-541) 731212, 731616, 739218.<br></br>
                            </p>
                            </Col>
                        </Row>

                        <div className="text-center">
                            <h4 className="fontSB">Booking Number</h4>
                            <p className="text-center">
                            (+62) 823-4652-8886
                            </p>

                            <img className="shadowimg img-fluid" src="./Images/contactus.jpg" alt="Diamond Hotel"></img>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="g-mapswrapper">
                <Row>
                    <Col>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.
                    673934471972!2d117.16152391457194!3d-0.4874439996443658!2m3!1f0!2f0!3f0!
                    3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2df67f6645d80247%3A0x6ecdc42f3a5ca13e!
                    2sHOTEL%20DIAMOND!5e0!3m2!1sid!2sid!4v1631514924494!5m2!1sid!2sid" 
                    width="100%" 
                    height="450"
                    style={{border:'0'}} 
                    allow="fullscreen"
                    title="Diamond Location" 
                    loading="lazy">
                    </iframe>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Contactus
