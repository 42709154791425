import { useState, useEffect } from 'react';
import { BiArrowToTop } from 'react-icons/bi';
import '../CSS/ScrollUp.css'


const ScrollUp = () =>{
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () =>{
        if (window.pageYOffset > 1700){
            setIsVisible(true);
        }
        else{
            setIsVisible(false);
        }
    }

    const ScrollToTop = () =>{
        window.scrollTo({
            top: 0,
            behavior:'smooth',
        });
    }

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        
        return() =>{
            window.removeEventListener('scroll', toggleVisibility);
        }
    }, [])


    return (
        <button onClick={ScrollToTop} className="ButtonToTop" style={{display: isVisible ? 'flex' : 'none'}}>
            <BiArrowToTop  aria-hidden="true"/>
        </button>
    )
}

export default ScrollUp;