import { Container, Row, Col } from "react-bootstrap"
import '../CSS/Footer.css'

const Footer = () => {
    return (
        <Container fluid={true} className="footerContainer">
            <Row>
                <Col sm={12} lg={4} className="footerleft">
                    <img src="/Images/logowhite.png" width="auto" height="auto" alt="Diamond Hotel Logo"></img>
                </Col>
                <Col sm={12} lg={4} className="footermiddle fontReg">
                    <h4 className="text-center fontSB"> Contact Info</h4>
                    <hr style={{border:'2px solid', color:'white', width:'33%', margin: 'auto', opacity:'100%'}}></hr>
                    <p>
                        Email: info@diamondhotel.co.id <br></br>
                        Phone: (+62-541) 731212, 731616, 739218 <br></br>
                        Whatsapp: (+62) 823-4652-8886 <br></br>
                    </p>
                </Col>
                <Col sm={12} lg={4} className="text-center footerright">
                    <h4 className="fontSB">Follow Us</h4>
                    <hr style={{border:'2px solid', color:'white', width:'27%', margin: 'auto', opacity:'100%'}}></hr>
                    <a href="https://www.instagram.com/diamondhotelsamarinda/"><img src="/Images/logoig.png" width="auto" height="auto" alt="Instagram"/></a>
                    <a href="https://www.facebook.com/DiamondHotelSamarinda/"><img src="/Images/logofb.png" width="auto" height="auto" alt="Facebook"/></a>
                </Col>
            </Row>

            <Row>
                <Col sm={12} lg={12} className="text-center">
                    <hr></hr>
                    <p>Copyright © 2014 www.diamondhotel.co.id<br></br>All Rights Reserved</p>
                </Col>
            </Row>
        </Container>
    )
}

export default Footer
