import {Container, Row, Col, Button} from 'react-bootstrap'
import { useState, useEffect } from 'react'
import '../CSS/Facilities.css'

const facsInfo = [
    {
        Id: "1",
        Title: "De'Spa",
        Description: 
        "A luxurious, tranquil unique peaceful massage that will let you discover Bali's natural heritage in your mind. Feeling the warm breeze of the ocean and the taste of Bali's greenery during this beautiful treatment. The Clarins will refule your mind and body with new power.",
        Opentime:"Opening hours: 10.00 AM – 10.00 PM",
        upperleftimg:"./Images/DeSpa1.jpg",
        upperrightimg:"./Images/DeSpa2.jpg",
        bottomleftimg:"./Images/DeSpa3.jpg",
        bottomrightimg:"./Images/DeSpa4.jpg",
        upperleftimgalt:"DeSpa 1",
        upperrightimgalt:"DeSpa 2",
        bottomleftimgalt:"DeSpa 3",
        bottomrightimgalt:"DeSpa 4",
    },
    {
        Id: "2",
        Title: "De'Resto",
        Description: "Our restaurant is available for breakfast, lunch and dinner. We serves up gourment and familiar savory food. Room service available for 24 hours with many selection guaranteed to satisfy you anytime in the privacy of your room.",
        Opentime:"Opening hours: 10.00 AM - 10.00 PM",
        upperleftimg:"./Images/DeResto1.jpg",
        upperrightimg:"./Images/DeResto2.jpg",
        bottomleftimg:"./Images/DeResto3.jpg",
        bottomrightimg:"./Images/DeResto4.jpg",
        upperleftimgalt:"DeResto 1",
        upperrightimgalt:"DeResto 2",
        bottomleftimgalt:"DeResto 3",
        bottomrightimgalt:"DeResto 4",
    },
    {
        Id: "3",
        Title: "Meeting Room",
        Description: "Making your meetings a success, choose any of our three meeting rooms, perfect for board meetings, business planning sessions as well as working lunches completed with audio-visual equipment. For tips and advice, our on-site coordinators will help plan and set up your meetings.",
        Opentime:"Reservation: +62 823-4652-8886",
        upperleftimg:"./Images/Kalimaya1.jpeg",
        upperrightimg:"./Images/Kalimaya2.jpeg",
        bottomleftimg:"./Images/Kalimaya3.jpeg",
        bottomrightimg:"./Images/Kalimaya4.jpeg",
        upperleftimgalt:"DeSpa 1",
        upperrightimgalt:"DeSpa 2",
        bottomleftimgalt:"DeSpa 3",
        bottomrightimgalt:"DeSpa 4",
    },
]

const Facilities = () => {
    const [currentId, setCurrentId] = useState("1");

    useEffect(() => {
    }, [currentId]);
  
    const showSelectedFact = () => {
      var fac = facsInfo.filter((x) => x.Id === currentId)[0];
      const { Title, Description, Opentime, Id } = fac;
      return (
        <div key={Id}>
          <h1 className="fontSB">{Title}</h1>
          <p>{Description}</p><br></br>
          <p><b>{Opentime}</b></p>
        </div>
      );
    };

    const showSelectedUpperImage = () => {
        var fac = facsInfo.filter((x) => x.Id === currentId)[0];
        const { upperleftimg, upperrightimg, upperleftimgalt, upperrightimgalt } = fac;
        return (
            <>
                <Col className="facsImage text-center" md={12} lg={6}>
                    <img className="img-fluid" src={upperleftimg} alt={upperleftimgalt}></img>
                </Col>

                <Col className="facsImage text-center" md={12} lg={6}>
                    <img className="img-fluid" src={upperrightimg} alt={upperrightimgalt}></img>
                </Col>
            </>
        );
      };


      const showSelectedBottomImage = () => {
        var fac = facsInfo.filter((x) => x.Id === currentId)[0];
        const { bottomleftimg, bottomrightimg, bottomleftimgalt, bottomrightimgalt} = fac;
        return (
            <>
                <Col className="facsImage text-center" sm={12} md={12} lg={6}>
                    <img className="img-fluid" src={bottomleftimg} alt={bottomleftimgalt}></img>
                </Col>

                <Col className="facsImage text-center" sm={12} md={12} lg={6}>
                    <img className="img-fluid" src={bottomrightimg} alt={bottomrightimgalt}></img>
                </Col>
            </>
        );
      };  

    return (
        <>
            <Container fluid>
                <Row className="facsHeader fontSB">
                    <Col lg={12} >
                        <h1>Facilities</h1>
                        <hr style={{border:'2px solid', color:'white', width:'22.7%', opacity:'100%'}}></hr>
                    </Col>
                </Row>
            </Container>

            <Container fluid>
                <Row className="facsbuttwrapper text-center">
                    <Col sm={4} lg={4}>
                        <Button
                        value="1"
                        variant="custom"
                        onClick={(e) => setCurrentId(e.target.value)}
                        >
                        De'Spa
                        </Button>
                    </Col>

                    <Col sm={4} lg={4} className="RestoButton">
                        <Button
                        value="2"
                        variant="custom"
                        onClick={(e) => setCurrentId(e.target.value)}
                        >
                        De'Resto
                        </Button>
                    </Col>

                    <Col sm={4} lg={4}>
                        <Button
                        value="3"
                        variant="custom"
                        onClick={(e) => setCurrentId(e.target.value)}
                        >
                        Meeting Room
                        </Button>
                    </Col>
                </Row>
            </Container>

            <Container fluid>
                <Row className="facsDetail fontReg">
                    <Col className="text-center" md={12} lg={12}>
                        {showSelectedFact()}
                    </Col>
                </Row>
            </Container>

            <Container fluid>
                <Row>
                    {showSelectedUpperImage()}
                </Row>
            </Container>

            <Container fluid>
                <Row>
                    {showSelectedBottomImage()}
                </Row>
            </Container>
        </>
    )
}

export default Facilities
