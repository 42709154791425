import {Switch, Route} from 'react-router-dom'
import Home from './Home'
import Facilities from './Facilities'
import Room from './Room'
import Contactus from './Contactus'

const Navigation = () => {
    return (
        <>
            <Switch>
                <Route exact path="/">
                    <Home/>
                </Route>
                <Route path="/Facilities">
                    <Facilities/>
                </Route>
                <Route path="/Room">
                    <Room/>
                </Route>
                <Route path="/Contactus">
                    <Contactus/>
                </Route>
            </Switch>
        </>
    )
}

export default Navigation