import {Navbar, Nav} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import '../CSS/Header.css'

const Header = () => {
    return (
        <>
            <Navbar className="MainHeader" expand="lg" variant="dark">
                <Navbar.Brand className="Headerlogo" as={Link} to={"/"}>
                    <img src="/Images/logowhite.png" alt="hotel_logo"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="Headerlist justify-content-end fontReg">
                    <Nav>
                        <Nav.Link as={Link} to={"/Facilities"}>Facilities</Nav.Link>
                        <Nav.Link as={Link} to={"/Room"}>Room</Nav.Link>
                        <Nav.Link as={Link} to={"/Contactus"}>Contact Us</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            
           <div class="elfsight-app-e6bc8ff7-5f01-430b-9bbe-6680a1495584"></div>
        </>
    )
}

export default Header