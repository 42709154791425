import '../CSS/Home.css';
import {Carousel, Container, Row, Col, Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import Scrollup from './Scrollup.js'

const Home = () => {
    return (
        <>     
            <Carousel fade>
                <Carousel.Item interval={2000}>
                    <img
                    className="d-block w-100"
                    src="./Images/Iklan1.jpg"
                    alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <img
                    className="d-block w-100"
                    src="./Images/Iklan2.jpg"
                    alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <img
                    className="d-block w-100"
                    src="./Images/Iklan3.jpg"
                    alt="Third slide"
                    />
                </Carousel.Item>
            </Carousel>

            <Container fluid>
                <Row className="Aboutus fontReg">
                    <Col sm={12} lg={6}>
                        <h1 className="fontSB">About Us</h1>
                        <hr style={{border:'2px solid', color:'#10255A', width:'26.5%', opacity:'100'}}></hr>
                        <p>Diamond Hotel serves as an urban oasis for both discerning business and leisure travelers. 
                            Located in the heart of Samarinda and close to the most prominent culinary tourism object. 
                            The hotel itself possess a trendy design with attention to comfort, quality and value based accommodation.
                            It features 75 modern rooms and suites with contemporary design environment to make you feel completely at home.
                        </p>

                        <Button 
                            variant="custom" 
                            style={{
                                fontSize: "21px",
                                backgroundColor: "#967A50",
                                padding: "8px 25px 8px 25px",
                                color:"white",
                                borderRadius: "5px",
                            }}
                            as={Link} to={"/Room"}
                            >
                            See Room
                        </Button>
                    </Col>

                    <Col sm={12} lg={6} className="aboutusimage text-center">
                        <img src="./Images/hotel1.jpeg" alt="Diamond Hotel" className="shadowimg img-fluid"></img>
                    </Col>
                </Row>
            </Container>

            <Container fluid>
                <Row className="Homegallery fontReg">
                    <Col lg={4} className="galleryleft">
                        <h1 className="fontSB">Gallery</h1>
                        <hr style={{width:'41%', margin:'15px 0px', border:'2px solid #967A50', opacity:'100'}}></hr>

                        <p> See our latest collection of pictures to make you feel mesmerize with our
                            hotel that provide comfort, quality and trendy feeling for your stay. 
                            We make sure that you feel completely at home.
                        </p>
                    </Col>

                    <Col className="galleryright" lg="8">
                        <Carousel fade>
                            <Carousel.Item interval={2000}>
                                <img
                                    className="d-block w-100"
                                    src="./Images/galeri1.jpg"
                                    alt="First slide"
                                />
                            </Carousel.Item>

                            <Carousel.Item interval={2000}>
                                <img
                                    className="d-block w-100"
                                    src="./Images/galeri2.jpg"
                                    alt="Second slide"
                                />
                            </Carousel.Item>

                            <Carousel.Item interval={2000}>
                                <img
                                    className="d-block w-100"
                                    src="./Images/galeri3.jpg"
                                    alt="Third slide"
                                />
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>
            </Container>

            <Container fluid>
                <Row className="servicepart fontReg">
                    <Col lg={6} className="serviceleft">
                        <h1 className="text-center fontSB">Services During <br></br>Your Stay</h1>
                        <hr style={{width:'30%', margin:'auto', border:'2px solid #10255A', opacity:'100'}}></hr>

                        <p> Diamond hotel provide multiple services that help our guest to have a pleasent feeling during their stay, we are here to make your visit an unforgettable one.
                            Any request you like to do during your stay, we will bring it to you at the ease of call.
                        </p>
                    </Col>

                    <Col lg={6} className="serviceright">
                        <Row>
                            <Col className="qualitybox text-center">
                                <img src="/Images/logobed.png" alt="#"></img>
                                <h3 className="fontSB">High Quality Beds</h3>
                                <p> Having a high quality bed is a must to improve the quality of your stay with us.
                                    We make sure that you have the best rest you can get.
                                </p>
                            </Col>

                            <Col className="qualitybox text-center">
                                <img src="/Images/logoconcierge.png" alt="#"></img>
                                <h3 className="fontSB">Concierge Service</h3>
                                <p> Provide guest with the best service to help guest with any request and question. 
                                </p>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="qualitybox text-center">
                                <img src="/Images/logolocation.png" alt="Bed_Logo"></img>
                                <h3 className="fontSB">Downtown Area</h3>
                                <p> Located in the heart of Samarinda City, staying at diamond hotel will help you to explore more because it's close to downtown area.
                                </p>
                            </Col>

                            <Col className="qualitybox text-center">
                                <img src="/Images/logowifi.png" alt="#"></img>
                                <h3 className="fontSB">24/H Wifi Access</h3>
                                <p> Enjoy 24 Hours of Wifi access within your room to help you with your internet daily needs.
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Scrollup/>
        </>
    )
}

export default Home
