import Header from './Components/Header'
import Navigation from "./Components/Navigation"
import Footer from "./Components/Footer"

function App() {
  return (
  <div>
    <Header/>
    <Navigation/>
    <Footer/>
  </div>
  );
}

export default App;
