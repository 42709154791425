import { Row, Col, Container, Button} from "react-bootstrap"
import { useState, useEffect } from 'react'
import '../CSS/Room.css'

const roomInfo = [
    {
        Id: "1",
        Title: "Superior",
        Description: 
        "A luxurious, tranquil unique peaceful massage that will let you discover Bali's natural heritage in your mind. Feeling the warm breeze of the ocean and the taste of Bali's greenery during this beautiful treatment. The Clarins will refule your mind and body with new power.",
        Mainimg:"./Images/SuperiorTwinMain.jpeg",
        upperleftimg:"./Images/Superior1.jpeg",
        upperrightimg:"./Images/Superior2.jpeg",
        bottomleftimg:"./Images/Superior3.jpeg",
        bottomrightimg:"./Images/SuperiorTwin2.jpeg",
        upperleftimgalt:"Superior 1",
        upperrightimgalt:"Superior 2",
        bottomleftimgalt:"Superior 3",
        bottomrightimgalt:"Superior 4",
        mainimgalt:"Superior Main"
    },
    {
        Id: "2",
        Title: "Deluxe",
        Description: 
        "A luxurious, tranquil unique peaceful massage that will let you discover Bali's natural heritage in your mind. Feeling the warm breeze of the ocean and the taste of Bali's greenery during this beautiful treatment. The Clarins will refule your mind and body with new power.",
        Mainimg:"./Images/DeluxeMain.jpg",
        upperleftimg:"./Images/Deluxe1.jpg",
        upperrightimg:"./Images/Deluxe2.jpg",
        bottomleftimg:"./Images/Deluxe3.jpg",
        bottomrightimg:"./Images/Deluxe4.jpg",
        upperleftimgalt:"Deluxe 1",
        upperrightimgalt:"Deluxe 2",
        bottomleftimgalt:"Deluxe 3",
        bottomrightimgalt:"Deluxe 4",
        mainimgalt:"Deluxe Main"
    },
    {
        Id: "3",
        Title: "Executive",
        Description: 
        "A luxurious, tranquil unique peaceful massage that will let you discover Bali's natural heritage in your mind. Feeling the warm breeze of the ocean and the taste of Bali's greenery during this beautiful treatment. The Clarins will refule your mind and body with new power.",
        Mainimg:"./Images/ExecutiveMain.jpg",
        upperleftimg:"./Images/Executive1.jpg",
        upperrightimg:"./Images/Executive2.jpg",
        bottomleftimg:"./Images/Executive3.jpg",
        bottomrightimg:"./Images/Executive1.jpg",
        upperleftimgalt:"Executive 1",
        upperrightimgalt:"Executive 2",
        bottomleftimgalt:"Executive 3",
        bottomrightimgalt:"Executive 4",
        mainimgalt:"Executive Main"
    },
    {
        Id: "4",
        Title: "Junior Suite",
        Description: 
        "A luxurious, tranquil unique peaceful massage that will let you discover Bali's natural heritage in your mind. Feeling the warm breeze of the ocean and the taste of Bali's greenery during this beautiful treatment. The Clarins will refule your mind and body with new power.",
        Mainimg:"./Images/JuniorMain.jpg",
        upperleftimg:"./Images/Junior1.jpg",
        upperrightimg:"./Images/Junior2.jpg",
        bottomleftimg:"./Images/Junior3.jpg",
        bottomrightimg:"./Images/Junior4.jpg",
        upperleftimgalt:"Junior Suite 1",
        upperrightimgalt:"Junior Suite 2",
        bottomleftimgalt:"Junior Suite 3",
        bottomrightimgalt:"Junior Suite 4", 
        mainimgalt:"Junior Suite Main"
    },
    {
        Id: "5",
        Title: "Diamond Suite",
        Description: 
        "The popular Diamond Suite is ideal for longer stays to make guests feel at home while being on the road. The bedroom have separate living room with sofa, tv and spacious area to make sure you feel comfortable during your stay. ",
        Mainimg:"./Images/DiamondMain.jpg",
        upperleftimg:"./Images/Diamond2.jpg",
        upperrightimg:"./Images/Diamond3.jpg",
        bottomleftimg:"./Images/Diamond4.jpg",
        bottomrightimg:"./Images/Diamond5.jpg",
        upperleftimgalt:"Diamond Suite 1",
        upperrightimgalt:"Diamond Suite 2",
        bottomleftimgalt:"Diamond Suite 3",
        bottomrightimgalt:"Diamond Suite 4",
        mainimgalt:"Diamond Suite Main"
    },
]

const Room = () => {
    const [currentId, setCurrentId] = useState("1");

    useEffect(() => {
    }, [currentId]);

    const showSelectedRoom = () => {
        var fac = roomInfo.filter((x) => x.Id === currentId)[0];
        const { Title, Description } = fac;
        return (
          <div>
            <h1 className="fontSB">{Title}</h1>
            <p>{Description}</p><br></br>
          </div>
        );
      };

    const showSelectedMainImage = () => {
        var fac = roomInfo.filter((x) => x.Id === currentId)[0];
        const { Mainimg, mainimgalt } = fac;
        return (
            <>
                <Col className="mainRoomImage text-center" sm={12} md={12} lg={12}>
                    <img className="img-fluid" src={Mainimg} alt={mainimgalt}></img>
                </Col>
            </>
        );
      };  

    const showSelectedUpperImage = () => {
        var fac = roomInfo.filter((x) => x.Id === currentId)[0];
        const { upperleftimg, upperrightimg, upperrightimgalt, upperleftimgalt } = fac;
        return (
            <>
                <Col className="roomImage text-center" md={12} lg={6}>
                    <img className="img-fluid" src={upperleftimg} alt={upperleftimgalt}></img>
                </Col>

                <Col className="roomImage text-center" md={12} lg={6}>
                    <img className="img-fluid" src={upperrightimg} alt={upperrightimgalt}></img>
                </Col>
            </>
        );
      };


      const showSelectedBottomImage = () => {
        var fac = roomInfo.filter((x) => x.Id === currentId)[0];
        const { bottomleftimg, bottomrightimg, bottomleftimgalt, bottomrightimgalt} = fac;
        return (
            <>
                <Col className="roomImage text-center" sm={12} md={12} lg={6}>
                    <img className="img-fluid" src={bottomleftimg} alt={bottomleftimgalt}></img>
                </Col>

                <Col className="roomImage text-center" sm={12} md={12} lg={6}>
                    <img className="img-fluid" src={bottomrightimg} alt={bottomrightimgalt}></img>
                </Col>
            </>
        );
      };  

    return (
        <>
            <Container className="roomChoice" fluid>
                <h1 className="text-center fontSB">Check out our room</h1>
                <hr style={{width:'26%', margin:'5px auto', border:'2px solid #967A50', opacity:'100'}}></hr>
        
                <Row className="normalChoice fontReg text-center">
                    <Col xs={4} sm={4} lg={4}>
                        <Button
                        value="1"
                        variant="custom"
                        onClick={(e) => setCurrentId(e.target.value)}
                        >
                        Superior
                        </Button>
                    </Col>

                    <Col xs={4} sm={4} lg={4}>
                        <Button
                        value="2"
                        variant="custom"
                        onClick={(e) => setCurrentId(e.target.value)}
                        >
                        Deluxe
                        </Button>
                    </Col>

                    <Col xs={4} sm={4} lg={4}>
                        <Button
                        value="3"
                        variant="custom"
                        onClick={(e) => setCurrentId(e.target.value)}
                        >
                        Executive
                        </Button>
                    </Col>
                </Row>

                <Row className="fontReg text-center">
                    <Col xs={6} sm={6} lg={6}>
                        <Button
                        value="4"
                        variant="custom"
                        onClick={(e) => setCurrentId(e.target.value)}
                        >
                        Junior Suite
                        </Button>
                    </Col>

                    <Col xs={6} sm={6} lg={6}>
                        <Button
                        value="5"
                        variant="custom"
                        onClick={(e) => setCurrentId(e.target.value)}
                        >
                        Diamond Suite
                        </Button>
                    </Col>
                </Row>
            </Container>

            <Container fluid>
                <Row>
                    {showSelectedMainImage()}
                </Row>
            </Container>

            <Container fluid>
                <Row className="roomDetail fontReg">
                    <Col lg={12} className="text-center">
                        {showSelectedRoom()}
                    </Col>
                </Row>
            </Container>

            <Container fluid>
                <Row style={{
                    justifyContent: "center",
                    margin: "auto",
                }}
                className="roomlogolist fontReg">
                    <Col lg={3} xs={3} className="roomfac text-center">
                       <img src="./Images/logoFridge.png" alt="Logo 1"/>
                       <h3 className="fontSB">Fridge</h3>
                    </Col>

                    <Col lg={3} xs={3}className="roomfac text-center">
                       <img src="./Images/logoAC.png" alt="Logo 2"/>
                       <h3 className="fontSB">AC</h3>
                    </Col>

                    <Col lg={3} xs={3}className="roomfac text-center">
                       <img src="./Images/logoWifi2.png" alt="Logo 3"/>
                       <h3 className="fontSB">Wifi</h3>
                    </Col>

                    <Col lg={3} xs={3}className="roomfac text-center">
                       <img src="./Images/logoTV.png" alt="Logo 4"/>
                       <h3 className="fontSB">TV</h3>
                    </Col>
                </Row>
            </Container>

            <Container fluid>
                <Row>
                    {showSelectedUpperImage()}
                </Row>
            </Container>

            <Container fluid>
                <Row>
                    {showSelectedBottomImage()}
                </Row>
            </Container>
        </>

    )
}

export default Room